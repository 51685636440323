import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

export default function NotFoundPage(): JSX.Element {
  return (
    <Layout>
      <Helmet>
        <title>Polle Kinderboek - 404</title>
      </Helmet>
      <h1 className="font-polle text-center text-2xl lg:text-5xl mt-20">
        Oops! Deze pagina was niet gevonden!
      </h1>
    </Layout>
  );
}
